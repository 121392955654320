<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup :label="'Effects & Post Processing'">
          <InputWrapper
            type="info"
            :label="'Effects applied to the scene after render'"
            :extra="'Active effects incur a performance cost and have individual settings.'"
          />

          <!-- <InputWrapper
            type="toggle"
            :label="'Bloom'"
            :value="getSetting('effects.bloom.enabled')"
            @change="onChange('effects.bloom.enabled', $event)"
          />
          <template v-if="getSetting('effects.bloom.enabled')">
            <InputWrapper
              type="slider"
              :label="'Luminance Threshold'"
              :value="getSetting('effects.bloom.threshold')"
              :min="0"
              :max="1"
              :step="0.01"
              @change="onChange('effects.bloom.threshold', $event)"
            />
            <InputWrapper
              type="slider"
              :label="'Luminance Smoothing'"
              :value="getSetting('effects.bloom.smoothing')"
              :min="0"
              :max="1"
              :step="0.01"
              @change="onChange('effects.bloom.smoothing', $event)"
            />
          </template>
           -->
          <InputWrapper
            type="toggle"
            :label="'SSR'"
            :value="getSetting('effects.SSR.enabled')"
            @change="onChange('effects.SSR.enabled', $event)"
          />

          <!--
          <InputWrapper
            type="slider"
            v-bind:label="'World Scale'"
            v-bind:value="getSetting('world.scale')"
            v-bind:min="scaleMin"
            v-bind:max="scaleMax"
            v-bind:step="scaleStep"
            v-on:change="onChange('world.scale', $event)"/>

          <InputWrapper
            type="position"
            v-bind:label="'World Position'"
            v-bind:values="worldPositions"
            v-bind:short="true"
            v-on:change="onChangeWorldPosition"/>

          <InputWrapper
            type="position"
            v-bind:label="'World Rotation'"
            v-bind:values="worldRotations"
            v-bind:min="rotateMin"
            v-bind:max="rotateMax"
            v-bind:step="rotateStep"
            v-bind:short="true"
            v-on:change="onChangeWorldRotation"/>

          <InputWrapper
            type="button"
            v-bind:simple="true"
            v-bind:value="'Set to Defaults'"
            v-on:change="onPositionReset"/> -->
        </BlockGroup>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '../GuiSettingMixin'

export default {
  name: 'ProcessingBlock',

  mixins: [mixin],

  data() {
    return {
      scaleMin: 0.1,
      scaleMax: 2,
      scaleStep: 0.1,

      rotateMin: -Math.PI,
      rotateMax: Math.PI,
      rotateStep: 0.1
    }
  },

  computed: {
    worldPositions() {
      return {
        x: this.getSetting('world.position.x'),
        y: this.getSetting('world.position.y'),
        z: this.getSetting('world.position.z')
      }
    },

    worldRotations() {
      return {
        x: this.getSetting('world.rotation.x'),
        y: this.getSetting('world.rotation.y'),
        z: this.getSetting('world.rotation.z')
      }
    }
  },

  methods: {
    onChangeWorldPosition(values) {
      if (values.x !== undefined) this.onChange('world.position.x', values.x)
      if (values.y !== undefined) this.onChange('world.position.y', values.y)
      if (values.z !== undefined) this.onChange('world.position.z', values.z)
    },

    onChangeWorldRotation(values) {
      if (values.x !== undefined) this.onChange('world.rotation.x', values.x)
      if (values.y !== undefined) this.onChange('world.rotation.y', values.y)
      if (values.z !== undefined) this.onChange('world.rotation.z', values.z)
    },

    onPositionReset() {
      this.onChange('world.position.x', 0)
      this.onChange('world.position.y', 0)
      this.onChange('world.position.z', 0)

      this.onChange('world.rotation.x', 0)
      this.onChange('world.rotation.y', 0)
      this.onChange('world.rotation.z', 0)

      this.onChange('world.scale', 1)
    }
  }
}
</script>
